import React from 'react'

const style = {
    height:"50px",
    background:"rgb(255,255,255)",
    width:"100%",
    bottom:"0",
    left:"0",
    padding:"10px 0px 10px 0px",
    textAlign:"Center",
    boxShadow:"10px 10px 10px 10px rgba(0,0,0,0.1)",
    marginTop:"50px",
}

export default function Footer() {
  return (
    <div style={style}>&copy; 
        Curio Res 2023
        <br/>
        Email us at curiores@gmail.com
    </div>
  )
}
