import React, { useEffect } from 'react'
import Tutorial from '../components/Tutorial.js'
import { styles } from '../utility/TutorialStyles.js';
import ReactGA from "react-ga4";

function Content() { 

  const imageWidth = '350px';
return(
<div>

Hi! I'm Curio Res.
<br/>
<br/>
I create tutorials for Arduino, PID control, signal processing, and more!
<br/>
Stay a while and discover something new.
<br/>
<h3>Tutorials</h3>
<div className="twoColumnContainer" style={{margin:"0em 2em",alignItems:"center",justifyContent:"center"}}>
<div className="twoColumnItemLink">
    <a href="./blinkingled"> 
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/ledThumb.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    Arduino Blinking LED Tutorial
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="./positioncontrol"> 
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_position_control.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    How to control a DC motor with an encoder
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="https://www.youtube.com/watch?v=HRaZLCBFVDE" target="_blank"> 
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_speed_control.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    DC motor PID speed control
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="https://www.youtube.com/watch?v=HJ-C4Incgpw" target="_blank">  
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_low-pass.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    How to design and implement a digital low-pass filter on an Arduino
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="https://www.youtube.com/watch?v=1oFlc4jv2-g" target="_blank">  
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_solar_charger.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    How to build an Arduino controlled solar charger
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="https://www.youtube.com/watch?v=3ozgxPi_tl0" target="_blank">  
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_multiple_motors.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    How to control multiple DC motors with encoders
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="https://www.youtube.com/watch?v=fIlklRIuXoY" target="_blank">  
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_processing_data.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    How to process Arduino data in Python
    </div>
    </div>
    </a>
  </div>
</div>

<h3>More from Curio Res</h3>

<div className="twoColumnContainer" style={{margin:"0em 2em",alignItems:"center",justifyContent:"center"}}>
  <div className="twoColumnItemLink">
    <a href="https://youtu.be/PF4fSRwPk5I" target="_blank"> 
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/bode_explained_thumb.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    Bode Plots Explained
    </div>
    </div>
    </a>
  </div>  
  <div className="twoColumnItemLink">
    <a href="./serialanalyzer"> 
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_serial_analyzer.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    The Serial Analyzer App
    </div>
    </div>
    </a>
  </div>
  <div className="twoColumnItemLink">
    <a href="https://www.youtube.com/watch?v=eM4VHtettGg" target="_blank"> 
    <div style={styles.imgContainer}>
    <img
      style={styles.imgStyle}
      width={imageWidth}
      src={`${process.env.PUBLIC_URL}/img/TutorialHome/thumb_lowpass2.png`}
      alt={" "}
    />
    <div style={styles.imgCaption}>
    Arduino Digital Low-Pass Filter 2.0
    </div>
    </div>
    </a>
  </div>
</div>

</div>
)
};

export default function Home() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/" });
  },[]);

  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/TutorialHome/cover.png')`}
      headerImage = {true}
      title="Curio Res"
      Content = {Content}
    />
  )
}

