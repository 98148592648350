import React, { useEffect } from 'react';
import Tutorial from '../components/Tutorial.js';
import ReactGA from "react-ga4";
import { styles } from '../utility/TutorialStyles.js';

export default function SerialAnalyzer() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/serialanalyzer" });
  },[]);
  
  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/serialAnalyzer/cover.png')`}
      headerImage = {true}
      title = "Serial Analyzer"
      Content = {Content}
    >
    </Tutorial>
  )
}

function Content() { 
return(
<div>
  
  <h2>Introduction</h2>
  The serial analyzer app allows you to easily review the spectrum of serial data computed in real time. Watch the video to learn about the app or download it from one of the links below.
  <br/>
  <br/>
  View the source code and learn more about the app on the GitHub page:
  <br/>
  &emsp; <a href="https://github.com/curiores/SerialAnalyzer">https://github.com/curiores/SerialAnalyzer</a>

  <h2>Video Tutorial</h2>
  <div style={styles.videoOuterWrap}>
    <div style={styles.videoWrap}>
      <iframe src="https://www.youtube.com/embed/6LcyG-hUZ2I?si=GUqyeY49XfCwJa1-" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen="true"
              style={styles.video}/>
    </div>
  </div>

  <h2 id="downloads">Downloads</h2>
  <ul>
    <li>Serial Analyzer App V1.1.0</li>
      <ul>
        <li>
          Windows: &nbsp;
          <a href={process.env.PUBLIC_URL + "/files/SerialAnalyzer/Serial Analyzer Setup 1.1.0.exe"} download="Serial Analyzer Setup 1.1.0.exe" target='_blank' rel="noreferrer">
          Serial Analyzer Setup 1.1.0.exe
          </a>
        </li>
      </ul>
      <ul>
        <li>
          MAC (dmg): &nbsp;
          <a href={process.env.PUBLIC_URL + "/files/SerialAnalyzer/Serial Analyzer-1.1.0-arm64.dmg"} download="Serial Analyzer-1.1.0-arm64.dmg" target='_blank' rel="noreferrer">
          Serial Analyzer-1.1.0-arm64.dmg
          </a>
        </li>
        <li>
          MAC (pkg): &nbsp;
          <a href={process.env.PUBLIC_URL + "/files/SerialAnalyzer/Serial Analyzer-1.1.0-arm64.pkg"} download="Serial Analyzer-1.1.0-arm64.pkg" target='_blank' rel="noreferrer">
          Serial Analyzer-1.1.0-arm64.pkg
          </a>
        </li>
        <li>
          MAC (zip): &nbsp;
          <a href={process.env.PUBLIC_URL + "/files/SerialAnalyzer/Serial Analyzer-1.1.0-arm64-mac.zip"} download="Serial Analyzer-1.1.0-arm64-mac.zip" target='_blank' rel="noreferrer">
          Serial Analyzer-1.1.0-arm64-mac.zip
          </a>
        </li>
      </ul>
      <li>Serial Analyzer App V1.0.1</li>
      <ul>
        <li>
          Windows: &nbsp;
          <a href={process.env.PUBLIC_URL + "/files/SerialAnalyzer/Serial Analyzer Setup 1.0.1.exe"} download="Serial Analyzer Setup 1.0.1.exe" target='_blank' rel="noreferrer">
          Serial Analyzer Setup 1.0.1.exe
          </a>
        </li>
        <li>
          Linux: &nbsp;
          <a href={process.env.PUBLIC_URL + "/files/SerialAnalyzer/serialanalyzer_1.0.1_amd64.deb"} download="serialanalyzer_1.0.1_amd64.deb" target='_blank' rel="noreferrer">
          serialanalyzer_1.0.1_amd64.deb
          </a>
        </li>
    </ul>
  </ul>
</div> 
)
};
