import React, { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import cpp from 'react-syntax-highlighter/dist/cjs/languages/hljs/cpp';
import shell from 'react-syntax-highlighter/dist/cjs/languages/hljs/shell';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactGA from "react-ga4";

import Tutorial from '../../components/Tutorial.js';
import { styles } from '../../utility/TutorialStyles.js';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const TableStyled = styled(Table)({
  backgroundColor:"rgb(29,31,33)",
  minWidth: 450, 
})
const TableCellStyled = styled(TableCell)({
  color: "rgb(230,230,230)",
  fontSize: "1em",
  padding:'0.9em',
  width:'50%',
})
const AccordionSummaryStyled = styled(AccordionSummary)({
  backgroundColor:"rgb(29,31,33)",
})
const AccordionDetailsStyled = styled(AccordionDetails)({
  backgroundColor:"rgb(29,31,33)",
})

// The final react component using the "tutorial" component
export default function VariantTutorial() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/variant" });
  },[]);
  
  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/positionControlTutorial/--.png')`}
      headerImage = {true}
      title = "Variants"
      Content = {Content}
    >
    </Tutorial>
  )
}

// The main content written in JSX
function Content() { 
return(
<div>
<h2>Variants (<code>std::variant</code> and <code>std::visit</code>)</h2>

<h3>Overview</h3>
You can use <code>std::variant</code> to define variables that may contain several different types. 
There are two basic options for how to work with the contents of a <code>std::variant</code>:
<ol>
  <li>Use <code>std::get</code> to extract the contents of the container. If the type is incorrect, this will lead to an exception.</li>
  <li>Use a visitor to prescribe how downstream code will handle different variable types.</li>
</ol>
Generally speaking, it often makes more sense to use a visitor, since it creates a more complete
picture with the defined variant. It also avoids using error handling when it really isn't necessary.


<h3>Basic Usage</h3>
<ThemeProvider theme={darkTheme}>
<TableContainer component={Paper}>
<TableStyled>
<TableHead>
    <TableRow>
      <TableCellStyled>Operation</TableCellStyled>
      <TableCellStyled>Syntax</TableCellStyled>
    </TableRow>
</TableHead>
<TableBody>
  <TableRow>
    <TableCellStyled>Declare a <code>std::variant</code> variable</TableCellStyled>
    <TableCellStyled>
<SyntaxHighlighter
  language={cpp} 
  style={tomorrowNight}
  showLineNumbers={false}
  customStyle={styles.syntax_table}>
{
String.raw`std::variant<typeA,typeB,etc> var;`
}
</SyntaxHighlighter> 
    </TableCellStyled>
  </TableRow>
  <TableRow>
    <TableCellStyled>Retrieve the variant contents as a specified type</TableCellStyled>
    <TableCellStyled>
<SyntaxHighlighter 
  language={cpp} 
  style={tomorrowNight}
  showLineNumbers={false}
  customStyle={styles.syntax_table}>
{
String.raw`std::get<typeA>(var);`
}
</SyntaxHighlighter>
    </TableCellStyled>
  </TableRow>
  <TableRow>
    <TableCellStyled>Apply a visitor (<code>Visitor</code>) to operate on a variant</TableCellStyled>
    <TableCellStyled>
<SyntaxHighlighter 
  language={cpp} 
  style={tomorrowNight}
  showLineNumbers={false}
  customStyle={styles.syntax_table}>
{
String.raw`std::visit(Visitor(),var);`
}
</SyntaxHighlighter>
    </TableCellStyled>
  </TableRow>
  <TableRow>
    <TableCellStyled>Include the header for <code>std::variant</code> and <code>std::visitor</code></TableCellStyled>
    <TableCellStyled>
<SyntaxHighlighter 
  language={cpp} 
  style={tomorrowNight}
  showLineNumbers={false}
  customStyle={styles.syntax_table}>
{
String.raw`#include <variant>`
}
</SyntaxHighlighter>
    </TableCellStyled>
  </TableRow>
</TableBody>
</TableStyled>
</TableContainer>
</ThemeProvider>

<h3>Examples</h3>
<b>Example 1a.</b> Define a <code>std::variant</code> and then extract its content using <code>std::get</code>.
<SyntaxHighlighter 
  language={cpp} 
  style={tomorrowNight}
  showLineNumbers={true}
  customStyle={styles.syntax_more}>
{
String.raw`// Define the variant: could be either string, int, or float  
std::variant<std::string,int,float> input = 3.0f;

// Extract the float from the variant and print it
float value = std::get<float>(input);
std::cout << value << std::endl;`
}
</SyntaxHighlighter> 

<b>Example 1b.</b> What do you think would happen if you asked <code>input</code> for a string instead?
<SyntaxHighlighter 
      language={cpp} 
      style={tomorrowNight}
      showLineNumbers={true}
      customStyle={styles.syntax_more}>{
String.raw`std::variant<std::string,int,float> input = 3.0f;
std::string value = std::get<std::string>(input);`
}</SyntaxHighlighter> 

This will compile. However, since you haven't defined a way to interpret the assigned float as a string, it will throw a runtime exception:
<SyntaxHighlighter 
      language={cpp} 
      style={tomorrowNight}
      showLineNumbers={false}
      customStyle={styles.syntax_more}>{
String.raw`terminate called after throwing an instance of 'std::bad_variant_access'
  what():  std::get: wrong index for variant`
}</SyntaxHighlighter> 

<b>Example 1c.</b> If you're accessing variants this way, you should make sure to 
handle and <code>std::bad_variant_access</code> exceptions that could result.
<SyntaxHighlighter 
      language={shell} 
      style={tomorrowNight}
      showLineNumbers={true}
      customStyle={styles.syntax_more}>{
String.raw`std::variant<std::string,int,float> input = 3.0f;
try{
    std::string value = std::get<std::string>(input);
}
catch(...){
    std::cout << "Oh no, I didn't have a string!" << std::endl;
}`
}</SyntaxHighlighter> 

<b>Example 2.</b> It's often better to use a visitor and handle all the possible cases. You can
define a visitor as a class or a struct and use it to operate on a std::variant. The visitor class overloads 
the () operator to handle all of the possible types contained within the variant. 
<br/><br/>
The first visitor in this example, <code>InputVisitor</code>, simply edits each value. 
The second one, <code>PrintVisitor</code>, prints the contents of the variant. Since all of these basic types are compatible 
with <code>std::cout</code>, <code>PrintVisitor</code> can be defined through a single function template.
<SyntaxHighlighter 
      language={cpp}
      style={tomorrowNight}
      showLineNumbers={true}
      customStyle={styles.syntax_more}>{
String.raw`#include <iostream>
#include <string>
#include <variant>

// This visitor operates on the contents of the variant
class InputVisitor{
  public:
    void operator()(std::string& input){
        input = input + "_visited";           
    }
    void operator()(int& input){
        input = input + 1;   
    }
    void operator()(float& input){
        input = input - 0.34;   
    }
};

// This visitor prints the contents of the variant
class PrintVisitor{
  public:
    template <typename T>
    void operator()(const T& input){
        std::cout << input;          
    }
};

int main()
{
  // Create the variant and print its contents
  std::variant<std::string,int,float> input = 3.0f;
  std::cout << "Input: ";
  std::visit(PrintVisitor(),input);
  std::cout << std::endl;

  // Apply the operating visitor
  std::visit(InputVisitor(),input);

  // Now print the altered result
  std::cout << "Processed input: ";
  std::visit(PrintVisitor(),input);
}`
}</SyntaxHighlighter> 


<h3>Exercises</h3>
<b>Ex 1.</b> Complete this exercise to familiarize yourself with the basic usage of <code>std::variant</code>.
<ol>
<li>Define a type called <code>ValuePair</code> with two independent members: a <code>std::string</code> and a <code>float</code>. </li>
<li>
  Define a variant type called <code>ValueInput</code> that can contain one of three types: 
  a <code>ValuePair</code>, a <code>std::string</code>, or a <code>float</code>. 
  Define <code>ValueInput</code> as a type alias (via the keyword <code>using</code>).
</li>
<li>
  Define a visitor for <code>ValueInput</code> that always returns a <code>ValuePair</code>. When given only a string, it fills in the float with value -1. 
  When give a float only, it fills in the string with "default".
 </li>
 <li>
  Test your definitions on instances of <code>ValuePair</code> and <code>ValueInput</code> of all three input types.
 </li>
</ol>

<ThemeProvider theme={darkTheme}>
<Accordion>
  <AccordionSummaryStyled
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
  >
    Example Solution
  </AccordionSummaryStyled>
  <AccordionDetailsStyled>
  <SyntaxHighlighter 
      language={cpp}
      style={tomorrowNight}
      showLineNumbers={true}
      customStyle={styles.syntax_table}>{
String.raw`#include <iostream>
#include <string>
#include <variant>
#include <tuple>

// 1-2. The basic types 
using ValuePair = std::tuple<std::string,double>;
using ValueInput = std::variant<ValuePair,std::string,double>;

// 3. The visitor
class ValueConverter{
  public:
    ValuePair operator()(ValuePair& vp){
        return vp;
    }
    ValuePair operator()(std::string& sIn){
        return ValuePair{sIn,0};
    }
    ValuePair operator()(double& dIn){
        return ValuePair{"default",dIn};
    }
};

// 4. Some testing 
int main(){
    // Create some data
    ValuePair vp1 {"V1",1};
    std::string s1 {"I was(am?) a string."};
    double x = 42;

    // A function for printing the ValuePair
    auto printVP = [](ValuePair& vp){ 
      std::cout << std::get<0>(vp) << " " << std::get<1>(vp) << std::endl; 
    };

    // Process the data
    ValueInput vi1 = vp1;
    ValuePair vp = std::visit(ValueConverter(),vi1);
    printVP(vp);

    vi1 = s1;
    vp = std::visit(ValueConverter(),vi1);
    printVP(vp);

    vi1 = x;
    vp = std::visit(ValueConverter(),vi1);
    printVP(vp);
}`
}</SyntaxHighlighter> 
  </AccordionDetailsStyled>
</Accordion>
</ThemeProvider>

<h3>History</h3>
<ul>
  <li><code>std::variant</code> was introduced with the C++ 17 standard.</li>
  <li><code>std::variant</code> is based on <code>boost::variant</code> (circa 2002).</li>
</ul>


</div> 
)
};
