export const styles = {
    syntax:{
      boxShadow: "rgba(50, 50, 93, 0.3) 0px 6px 15px -2px, rgba(0, 0, 0, 0.2) 0px 4px 8px -4px",
      margin: "2em 0em",
      lineHeight:"1.4",
      fontSize:"1em",
      padding:"2em 1em",
    },
    syntax_more:{
      boxShadow: "rgba(50, 50, 93, 0.4) 0px 10px 15px -2px, rgba(0, 0, 0, 0.2) 0px 4px 8px -4px",
      margin: "1.5em 0em",
      lineHeight:"1.4",
      fontSize:"1em",
      padding:"1.5em 1em",
    },
    syntax_table:{
      margin: "0em 0em",
      lineHeight:"1.4",
      fontSize:"1em",
      padding:"0em 0em",
    },
    syntax_inline:{
      display: "inline",
    },
    video:{
      position:"absolute",
      top:0,
      left:0,
      width:"100%",
      height:"100%",
    },
    videoWrap:{
      display:"block",
      margin:"auto",
      position:"relative",
      paddingBottom:"56.25%",
    },
    videoOuterWrap:{
      width:"85%",
      margin:"auto",
      minWidth:"400px",
    },
    imgContainer:{
      display:"flex",
      flex:1,
      flexDirection:"column",
      alignItems:'center',
      justifyContent:'center'
    },
    imgStyle:{
      display:"block",
      margin:"1em 1em 0.5em 1em",
      boxShadow: "rgba(50, 50, 93, 0.3) 0px 6px 15px -2px, rgba(0, 0, 0, 0.2) 0px 4px 8px -4px",
    },
    imgCaption:{
      display:"block",
      fontWeight: "300",
      textAlign:"center",
      color:"rgb(0,0,0)",
      fontSize:"0.9em",
      padding:"0px 1em"
    }
  }