import React, { useEffect } from 'react'
import MenuBar from './components/MenuBar.js'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home.js';
import CNCShieldV3 from './pages/CNCShieldV3.js';
import PositionControl from './pages/PositionControl.js';
import TensCpp from './pages/TensCpp.js';
import VariantTutorial from './pages/TensCpp/Variant.js';
import BlinkingLed from './pages/BlinkingLed.js';
import Footer from './components/Footer.js';
import ReactGA from "react-ga4";
import './App.css';
import SerialAnalyzer from './pages/SerialAnalyzerApp.js';

const styles = {
  container:{
    minHeight:"calc(100vh - 120px)",
    width:"100%"
  }
}

function App() {
  useEffect(() =>{
    ReactGA.send({ hitType: "Apploaded", page: "/" });
  },[]);

  return (
    <Router >
      <div style={styles.container}>
        <MenuBar/>
        <Routes>
          <Route path='/' element={<Home/>}/> 
          <Route path='/positioncontrol' element={<PositionControl/>}/> 
          <Route path='/blinkingled' element={<BlinkingLed/>}/> 
          <Route path='/serialanalyzer' element={<SerialAnalyzer/>}/>
          <Route path='/cncshieldv3' element={<CNCShieldV3/>}/> 
          <Route path='/tenscpp' element={<TensCpp/>}/> 
          <Route path='/variant' element={<VariantTutorial/>}/> 
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
