import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: 'rgb(10, 18, 35)',
            light: '#91a3de',
        },
        secondary: {
            main: '#65a9ff',
        },
        background: {
            default: '#ffffff',
            paper: '#f5f5f5',
        },
    },
});