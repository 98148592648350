import React, { useEffect } from 'react';
import Tutorial from '../components/Tutorial.js';
import SyntaxHighlighter from 'react-syntax-highlighter';
import arduino from 'react-syntax-highlighter/dist/cjs/languages/hljs/arduino';
import { arduinoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styles } from '../utility/TutorialStyles.js';
import ReactGA from "react-ga4";

// The final react component using the "tutorial" component
export default function BlinkingLed() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/blinkingled" });
  },[]);
  
  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/blinkingLed/blinking_led_banner.png')`}
      headerImage = {true}
      title = "Arduino Blinking LED Tutorial"
      Content = {Content}
    >
    </Tutorial>
  )
}

// The main content written in JSX
function Content() { 
return(
<div>

<h2>Introduction</h2>

The blinking LED is a great place to start with Arduino. This 3-minute video tutorial will walk you through the steps. 
The diagram and code below is available for reference while you put together your own circuit!

<h2>Video Tutorial</h2>
  <div style={styles.videoOuterWrap}>
    <div style={styles.videoWrap}>
      <iframe src="https://www.youtube.com/embed/I0ZIrzoI61g?si=f3FKl1E-0YxQ75MM" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen="true"
              style={styles.video}/>
    </div>
  </div>

<h2>Parts List</h2>
For this project, you'll need: 
<ul>
  <li>A microcontroller</li>
  <li>A small LED</li>
  <li>A resistor (220Ω)</li>
  <li>Two jumper wires</li>
</ul>

You can calculate the resistor you'll need using Ohm's law. For example, a small red LED drops around 2V and uses about 15 mA. The Arduino pins provide 5V.
So, you'll want the resistor to drop around 3V. That means the required resistance is R = (5V - 2V)/(15 mA) = 200Ω. So, a 220Ω resistor will work fine.
Increasing the resistance lowers the current, dimming the LED. Don't use a very low resistance. Providing too much current can burn out the LED or damage the Arduino, 
which only provides a maximum of 20 mA continuously from a digital output pin.

<h2>Circuit</h2>

Connect the LED and resistor in series on a breadboard. That way, the current will flow in a single loop from the Arduino and through the LED and the resistor.
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle,width:"700px"}}
    src={`${process.env.PUBLIC_URL}/img/blinkingLed/series_parallel.png`}
    alt={"Blinking LED circuit"}
  />
  <div style={styles.imgCaption}>
  Connect the LED and resistor in series, not in parallel.
  </div>
</div>

LEDs have a polarity. Make sure that the negative terminal of the LED (the shorter leg) is connected to the Arduino ground pin.
The other side of the circuit (including the resistor) should be connected to Arduino pin 8 (or any of the digital output pins).
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle,width:"600px",backgroundColor:"black"}}
    src={`${process.env.PUBLIC_URL}/img/blinkingLed/led_image.png`}
    alt={"Blinking LED circuit"}
  />
  <div style={styles.imgCaption}>
  Completed blinking LED circuit
  </div>
</div>




Here's how your circuit should look when you're done.
<h2></h2>
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle,width:"600px"}}
    src={`${process.env.PUBLIC_URL}/img/blinkingLed/blinking_led_completed_circuit.png`}
    alt={"Blinking LED circuit"}
  />
  <div style={styles.imgCaption}>
  Completed blinking LED circuit
  </div>
</div>

<h2>Code</h2> 

Once you've connected your circuit, you can make the LED blink by writing code and uploading it to the Arduino. The code below shows the completed blinking LED program.
<SyntaxHighlighter 
  language={arduino} 
  style={arduinoLight}
  showLineNumbers={true}
  customStyle={styles.syntax}>
{
String.raw`#define led_pin 8

void setup() {
  // We're going to write to the pin, so we set its mode to OUTPUT 
  pinMode(led_pin, OUTPUT);
}

void loop() {
  digitalWrite(led_pin,HIGH); // Turn the LED on
  delay(1000); // wait a second

  digitalWrite(led_pin,LOW); // Turn the LED off
  delay(1000); // wait a second
}`
}
</SyntaxHighlighter> 

Try adjusting the delays to make the LED blink faster or slower. The delays are in miliseconds, so a delay(5000) would delay for 5 seconds.

Thanks for reading and send me an email (curiores@gmail.com) if you have questions!
</div> 
)
};
