import React, { useEffect } from 'react';
import Tutorial from '../components/Tutorial.js';
import SyntaxHighlighter from 'react-syntax-highlighter';
import arduino from 'react-syntax-highlighter/dist/cjs/languages/hljs/arduino';
import { arduinoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styles } from '../utility/TutorialStyles.js';
import ReactGA from "react-ga4";

// The final react component using the "tutorial" component
export default function PositionControl() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/positioncontrol" });
  },[]);
  
  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/positionControlTutorial/motorPosition.png')`}
      headerImage = {true}
      title = "How to control a DC motor with an encoder"
      Content = {Content}
    >
    </Tutorial>
  )
}

// The main content written in JSX
function Content() { 
return(
<div>
<h2>Introduction</h2>
  By itself, a DC motor can't be controlled like a servo motor or a stepper motor. But add an encoder, 
  and you unlock the full potential of the DC motor. Using this approach, you can harness the simplicity,
   even torque, and lightweight profile of a DC motor for your controlled application.
  <br/>
  In this tutorial, you'll learn how to control a motor with an attached magnetic encoder using the
   PID algorithm. You can watch a full walkthrough in the video below and then follow the steps
    in this tutorial while you work through the project.
<h2>Video Tutorial</h2>
  <div style={styles.videoOuterWrap}>
    <div style={styles.videoWrap}>
      <iframe src="https://www.youtube.com/embed/dTGITLnYAY0" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen="true"
              style={styles.video}/>
    </div>
  </div>
<h2>Parts List</h2>
<div className="twoColumnContainer">
  <div className="twoColumnItem">
    For this project, you'll need: 
    <ul>
      <li>A microcontroller</li>
      <li>A suitable motor driver</li>
      <li>A DC motor with an encoder</li>
      <li>A suitable power supply</li>
    </ul>
  </div>
  <div className="twoColumnItem">
    Here's what I used:
    <ul className="blueLink">
      <li>An Arduino Uno</li>
      <li><a rel="noreferrer noopener" href="https://www.pololu.com/product/2999" 
             data-type="URL" data-id="https://www.pololu.com/product/2999" target="_blank">
              A TB67H420FTG Motor Driver on a Pololu carrier 
      </a></li>
      <li><a rel="noreferrer noopener" href="https://www.pololu.com/product/4751" target="_blank">12V 37D geared motor with encoder</a></li>
      <li>A 12V power supply</li>
    </ul>
  </div>
</div>


<h2>Part 1: Reading from the Encoder</h2>
<h3>How an encoder works</h3>
An encoder works by observing changes to the magnetic field created by a magnet attached the motor shaft. 
As the motor rotates, the encoder outputs will trigger periodically.
<div style={styles.imgContainer}>
  <img
    style={styles.imgStyle}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/encoderExplanation.png`}
    alt={"Encoder rotation"}
  />
  <div style={styles.imgCaption}>
  When the magnet spins clockwise, output A will trigger first.
  <br/>
  When rotated counterclockwise, on the other hand, output B will trigger first.
  </div>
</div>
<h3>Read from the encoder</h3>
Connect the encoder to the Arduino using the spec sheet for your encoder. Make sure you connect the
 output pins of the encoder to pins with interrupts (for an Arduino Uno that means pins 2 and 3). For example:
<ul>
  <li>Encoder A output →&nbsp;DO pin 2</li>
  <li>Encoder B output →&nbsp;DO pin 3</li>
  <li>Encoder ground →&nbsp;Arduino GND</li>
  <li>Encoder VCC →&nbsp;Arduino 5V</li>
</ul>
<div style={styles.imgContainer}>
  <img
    style={styles.imgStyle}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/connections.png`}
    alt={"Encoder rotation"}
  />
  <div style={styles.imgCaption}>
  Here are the connections I used
  </div>
</div>
You can read directly from the encoder pins using the code shown below.
<SyntaxHighlighter 
  language={arduino} 
  style={arduinoLight}
  showLineNumbers={true}
  customStyle={styles.syntax}>
{
String.raw`#define ENCA 2 // Yellow
#define ENCB 3 // White

void setup() {
  Serial.begin(9600);
  pinMode(ENCA,INPUT);
  pinMode(ENCB,INPUT);
}

void loop() {
  int a = digitalRead(ENCA);
  int b = digitalRead(ENCB);
  Serial.print(a*5); 
  Serial.print(" ");
  Serial.print(b*5);
  Serial.println();
}`
}
</SyntaxHighlighter> 
Try rotating the encoder magnet and viewing the output in the serial plotter.
<div style={styles.imgContainer}>
  <img
    style={styles.imgStyle}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/rotation.png`}
    alt={"Encoder rotation"}
  />
  <div style={styles.imgCaption}>
  When you rotate the encoder clockwise, output A triggers first. 
  <br/>Rotate counterclockwise, and output B triggers first.
  </div>
</div>


<h2>Part 2: Measure position</h2>
When measuring position you don't want to read from the DO pins in the loop function. 
Instead, you want to trigger a function each time output A rises, and then increment the position. 
The code below triggers an interrupt when A rises, and then either adds one to the position (if output B is high) 
or subtracts one (if output B is low).
<br/>
In order to ensure that the posi variable is stored so that it can be accurately read by both the 
loop and interrupt functions, you need to use the volatile qualifier. The volatile keyword prevents 
the compiler from performing optimizations on the variable that could potentially lead to it being misread. 
In addition to the volatile directive, an ATOMIC_BLOCK macro is needed to access the position variable. 
The ATOMIC_BLOCK macro prevents the interrupt from changing part of the posi variable while it is being read.
 Without the ATOMIC_BLOCK macro, it is possible for the interrupt to change part of the posi while it is 
 being read, leading to a completely different reading of the variable.
<SyntaxHighlighter 
  language={arduino} 
  style={arduinoLight}
  showLineNumbers={true}
  customStyle={styles.syntax}>
{
String.raw`#include <util/atomic.h> // For the ATOMIC_BLOCK macro

#define ENCA 2 // YELLOW
#define ENCB 3 // WHITE

volatile int posi = 0; // specify posi as volatile

void setup() {
  Serial.begin(9600);
  pinMode(ENCA,INPUT);
  pinMode(ENCB,INPUT);
  attachInterrupt(digitalPinToInterrupt(ENCA),readEncoder,RISING);
}

void loop() {
  // Read the position in an atomic block to avoid a potential
  // misread if the interrupt coincides with this code running
  // see: https://www.arduino.cc/reference/en/language/variables/variable-scope-qualifiers/volatile/
  int pos = 0; 
  ATOMIC_BLOCK(ATOMIC_RESTORESTATE) {
    pos = posi;
  }
  
  Serial.println(pos);
}

void readEncoder(){
  int b = digitalRead(ENCB);
  if(b > 0){
    posi++;
  }
  else{
    posi--;
  }
}`
}
</SyntaxHighlighter> 
<h2>Part 3: Drive the motor</h2>
Connect the motor and motor driver as
<ul>
  <li>Motor terminal 1 → Motor driver output 1</li>
  <li>Motor terminal 2 → Motor driver output 2</li>
  <li>Power supply → Motor driver Vin/GND</li>
  <li>Motor driver GND → Arduino GND</li>
  <li>Motor driver PWMA input → Arduino pin 5 [pwm]</li>
  <li>Motor driver IN1 input → Arduino pin 7</li>
  <li>Motor driver IN2 input →Arduino pin 6</li>
</ul>
You can test the motor driver directly by writing to the driver pins in the loop function. However, 
for later use, it's useful to write a function that will set the speed and direction. 
See the setMotor function in the code below.
<div style={styles.imgContainer}>
  <img
    style={styles.imgStyle}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/motorConnections.png`}
    alt={"Encoder rotation"}
  />
  <div style={styles.imgCaption}>
  Motor driver connections
  </div>
</div>
<SyntaxHighlighter 
  language={arduino} 
  style={arduinoLight}
  showLineNumbers={true}
  customStyle={styles.syntax}>
{
String.raw`#include <util/atomic.h> // For the ATOMIC_BLOCK macro

#define ENCA 2 // YELLOW
#define ENCB 3 // WHITE
#define PWM 5
#define IN2 6
#define IN1 7

volatile int posi = 0; // specify posi as volatile: https://www.arduino.cc/reference/en/language/variables/variable-scope-qualifiers/volatile/

void setup() {
  Serial.begin(9600);
  pinMode(ENCA,INPUT);
  pinMode(ENCB,INPUT);
  attachInterrupt(digitalPinToInterrupt(ENCA),readEncoder,RISING);
  
  pinMode(PWM,OUTPUT);
  pinMode(IN1,OUTPUT);
  pinMode(IN2,OUTPUT);
}

void loop() {
  
  // Read the position in an atomic block to avoid a potential
  // misread if the interrupt coincides with this code running
  // see: https://www.arduino.cc/reference/en/language/variables/variable-scope-qualifiers/volatile/
  int pos = 0; 
  ATOMIC_BLOCK(ATOMIC_RESTORESTATE) {
    pos = posi;
  }

  setMotor(1, 25, PWM, IN1, IN2);
  delay(200);
  Serial.println(pos);
  setMotor(-1, 25, PWM, IN1, IN2);
  delay(200);
  Serial.println(pos);
  setMotor(0, 25, PWM, IN1, IN2);
  delay(20);
  Serial.println(pos);
}

void setMotor(int dir, int pwmVal, int pwm, int in1, int in2){
  analogWrite(pwm,pwmVal);
  if(dir == 1){
    digitalWrite(in1,HIGH);
    digitalWrite(in2,LOW);
  }
  else if(dir == -1){
    digitalWrite(in1,LOW);
    digitalWrite(in2,HIGH);
  }
  else{
    digitalWrite(in1,LOW);
    digitalWrite(in2,LOW);
  }
}

void readEncoder(){
  int b = digitalRead(ENCB);
  if(b > 0){
    posi++;
  }
  else{
    posi--;
  }
}`
}
</SyntaxHighlighter> 


<h2>Part 4: Control the motor</h2>
<div style={styles.imgContainer}>
  <img
    style={styles.imgStyle}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/loop.png`}
    alt={"Encoder rotation"}
  />
  <div style={styles.imgCaption}>
  So far, the components have been connected in a loop, but the motor position isn't actually being controlled.
  </div>
</div>
To construct the feedback control loop, you must:
<ol>
  <li>Define a target position</li>
  <li>Compute the error e(t) as the difference between the measured position and the target</li>
  <li>Compute a control signal u(t)</li>
</ol>
<p>The control signal <em>u</em>(<em>t</em>) can be computed using the PID (<strong>P</strong>roportional 
<strong>I</strong>ntegral <strong>D</strong>erivative) control algorithm. The control signal is computed as a sum of three terms:</p>
  <img
    style={{...styles.imgStyle,transform:"scale(0.8)"}}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/ucalc.png`}
    alt={"Encoder rotation"}
  />
To compute the integral and derivative terms, you can use simple finite difference approximations:
<img
    style={{...styles.imgStyle,transform:"scale(0.8)"}}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/fd.png`}
    alt={"Encoder rotation"}
  />
The code below shows the implementation I used for the Arduino.
<SyntaxHighlighter 
  language={arduino} 
  style={arduinoLight}
  showLineNumbers={true}
  customStyle={styles.syntax}>
{
String.raw`#include <util/atomic.h> // For the ATOMIC_BLOCK macro

#define ENCA 2 // YELLOW
#define ENCB 3 // WHITE
#define PWM 5
#define IN2 6
#define IN1 7

volatile int posi = 0; // specify posi as volatile: https://www.arduino.cc/reference/en/language/variables/variable-scope-qualifiers/volatile/
long prevT = 0;
float eprev = 0;
float eintegral = 0;

void setup() {
  Serial.begin(9600);
  pinMode(ENCA,INPUT);
  pinMode(ENCB,INPUT);
  attachInterrupt(digitalPinToInterrupt(ENCA),readEncoder,RISING);
  
  pinMode(PWM,OUTPUT);
  pinMode(IN1,OUTPUT);
  pinMode(IN2,OUTPUT);
  
  Serial.println("target pos");
}

void loop() {

  // set target position
  //int target = 1200;
  int target = 250*sin(prevT/1e6);

  // PID constants
  float kp = 1;
  float kd = 0.025;
  float ki = 0.0;

  // time difference
  long currT = micros();
  float deltaT = ((float) (currT - prevT))/( 1.0e6 );
  prevT = currT;

  // Read the position in an atomic block to avoid a potential
  // misread if the interrupt coincides with this code running
  // see: https://www.arduino.cc/reference/en/language/variables/variable-scope-qualifiers/volatile/
  int pos = 0; 
  ATOMIC_BLOCK(ATOMIC_RESTORESTATE) {
    pos = posi;
  }
  
  // error
  int e = pos - target;

  // derivative
  float dedt = (e-eprev)/(deltaT);

  // integral
  eintegral = eintegral + e*deltaT;

  // control signal
  float u = kp*e + kd*dedt + ki*eintegral;

  // motor power
  float pwr = fabs(u);
  if( pwr > 255 ){
    pwr = 255;
  }

  // motor direction
  int dir = 1;
  if(u<0){
    dir = -1;
  }

  // signal the motor
  setMotor(dir,pwr,PWM,IN1,IN2);


  // store previous error
  eprev = e;

  Serial.print(target);
  Serial.print(" ");
  Serial.print(pos);
  Serial.println();
}

void setMotor(int dir, int pwmVal, int pwm, int in1, int in2){
  analogWrite(pwm,pwmVal);
  if(dir == 1){
    digitalWrite(in1,HIGH);
    digitalWrite(in2,LOW);
  }
  else if(dir == -1){
    digitalWrite(in1,LOW);
    digitalWrite(in2,HIGH);
  }
  else{
    digitalWrite(in1,LOW);
    digitalWrite(in2,LOW);
  }  
}

void readEncoder(){
  int b = digitalRead(ENCB);
  if(b > 0){
    posi++;
  }
  else{
    posi--;
  }
}`
}
</SyntaxHighlighter> 
Try adjusting the target position and test the responses. 
If you see some overshoot or jitter, try adjusting the PID parameters: kp, kd, and ki.
<div style={styles.imgContainer}>
  <img
    style={styles.imgStyle}
    src={`${process.env.PUBLIC_URL}/img/positionControlTutorial/test.png`}
    alt={"Encoder rotation"}
  />
  <div style={styles.imgCaption}>
    I saw a little overshoot. Setting kd = 0.025 removed it.
  </div>
</div>
<br/>
Thanks for reading and send me an email (curiores@gmail.com) if you have questions!
</div> 
)
};
