import React, { useEffect } from 'react';
import Tutorial from '../components/Tutorial.js';
import SyntaxHighlighter from 'react-syntax-highlighter';
import cpp from 'react-syntax-highlighter/dist/cjs/languages/hljs/cpp';
import shell from 'react-syntax-highlighter/dist/cjs/languages/hljs/shell';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styles } from '../utility/TutorialStyles.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ReactGA from "react-ga4";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const TableStyled = styled(Table)({
  backgroundColor:"rgb(29,31,33)",
  minWidth: 450, 
})
const TableCellStyled = styled(TableCell)({
  color: "rgb(230,230,230)",
  padding:'0.9em',
  fontSize: '1em',
  width:'33%',
})
const AccordionSummaryStyled = styled(AccordionSummary)({
  backgroundColor:"rgb(29,31,33)",
})
const AccordionDetailsStyled = styled(AccordionDetails)({
  backgroundColor:"rgb(29,31,33)",
})

// The final react component using the "tutorial" component
export default function CppPage1() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/CppPage1" });
  },[]);
  
  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/positionControlTutorial/--.png')`}
      headerImage = {true}
      title = "Tens C++"
      Content = {Content}
    >
    </Tutorial>
  )
}

// The main content written in JSX
function Content() { 
return(
<div>
<h1>Introduction</h1>
  C++ is particularly difficult to learn because the resources that teach it tend to be highly technical.
  In reality, basic usage of C++ isn't as difficult as you might expect from its reputation.
  The goal of these tutorials is to communicate how to use modern C++ tools in a simple way, with
  minimal technical jargon. More detailed technical explanations are available from many other
  resources.
  <br/><br/>
  <b>Tens C++</b> primarily covers functionality form the tens (2010-2019) C++ standards: C++11, C++14, and C++17. These critical standards 
  incorporate important Boost functionality that lead to an even more template-heavy style of C++ with safer 
  memory management and greater type flexibility.
  <br/><br/>
  Tens C++ is not intended to be documentation; indeed, much of the syntax is intentionally omitted. 
  Additionally, the resource is not by any means intended to be exhaustive or examine the details of how the templates operate.
  Instead, it is intended to provide the basic information required to get started with the tens C++ funcionality.

<h1>Tutorials</h1>

<ThemeProvider theme={darkTheme} >
<TableContainer component={Paper}>
<TableStyled>
<TableHead>
    <TableRow>
      <TableCellStyled>C++11</TableCellStyled>
      <TableCellStyled>C++14</TableCellStyled>
      <TableCellStyled>C++17</TableCellStyled>
    </TableRow>
</TableHead>
<TableBody>
  <TableRow>
    <TableCellStyled></TableCellStyled>
    <TableCellStyled></TableCellStyled>
    <TableCellStyled className="lightBlueLink">
    <a href="/variant" >Variants <br/> (<code>std::variant</code> and <code>std::visit</code>)</a>
    </TableCellStyled>
  </TableRow>
</TableBody>
</TableStyled>
</TableContainer>
</ThemeProvider>



</div> 
)
};
