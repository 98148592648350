import React from 'react'
import Paper from '@mui/material/Paper';

import { ThemeProvider } from '@mui/material/styles';
import { Theme } from '../utility/Theme.js'

// Props:
//   backgroundImage
//   title
//   headerImage (boolean)
//   Content

const styles = {
  headerImage:{
    width:"100%",
    margin:"0px",
    height:"300px",
    backgroundColor:"rgba(0,0,0,0.5)",
    backgroundSize: "100%",
    backgroundPosition:"center",
    backgroundRepeat: 'no-repeat',
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  headerCover:{
    width:"100%",
    height:"100%",
    left:"0",
    top:"0",
    background:"rgb(0,0,0,0.5)",
    display:"flex",
    flexDirection:"column",
  },
  title:{
    margin:"auto auto 20px auto",
    color:"rgb(250,250,250)",
    fontFamily:"montserrat, Source Sans Pro, sans-serif, Roboto, Helvetica, Arial",
    fontSize:"28px",
    padding:"0.5em 1em"
  },
  paper:{
    width:"85%",
    maxWidth:"950px",
    minWidth:"400px",
    minHeight:"500px",
    margin:"auto",
    marginTop:"4em",
    marginBottom:"8em",
    padding:"2.25em 3em",
    paddingBottom:"4em",
    fontFamily:"Source Sans Pro, sans-serif, Roboto, Helvetica, Arial",
    lineHeight:"1.7",
    fontSize:"1em",
    color:"rgba(80,80,80)",
    fontWeight:"400",
  }
}



export default function Tutorial(props) {

  const propStyles = {background: props.backgroundImage};
  const Content = props.Content;
  return (
    <ThemeProvider theme={Theme}>
      {props.headerImage?
        <div style={{...propStyles,...styles.headerImage}}>
          <div style={styles.headerCover}>
            <div style={styles.title}>
              {props.title}
            </div>
          </div>
        </div>
        :
        <div>
        </div>
      }
      <Paper 
          style={styles.paper}
          elevation={4}
      >
        <Content/>
      </Paper>
    </ThemeProvider>
  )
}
