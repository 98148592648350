import React, { useEffect } from 'react';
import Tutorial from '../components/Tutorial.js';
import SyntaxHighlighter from 'react-syntax-highlighter';
import arduino from 'react-syntax-highlighter/dist/cjs/languages/hljs/arduino';
import { arduinoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styles } from '../utility/TutorialStyles.js';
import ReactGA from "react-ga4";

// The final react component using the "tutorial" component
export default function CNCShieldV3() {
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: "/CNCShieldV3" });
  },[]);
  
  return (
    <Tutorial 
      backgroundImage = {`url('${process.env.PUBLIC_URL}/img/positionControlTutorial/--.png')`}
      headerImage = {true}
      title = "Ardunio CNC Shield V3"
      Content = {Content}
    >
    </Tutorial>
  )
}

// The main content written in JSX
function Content() { 
return(
<div>
<h2>Introduction</h2>
  Stepper motors are useful for a wide variety of projects. In this tutorial, I will show you how to use the 
  Arduino CNC Shield V3.0 with A4988 or DRV8825 stepper motor drivers. 
  Note that this system only powers small steppers (the size used in 3D printers), so be sure to respect the current limits.
  The A4988 IC supports a maximum current of 2 A and the DRV8825 supports slightly more: 2.5 A. 
  These maximum ratings are dependent on appropriate heat dissipation for the ICs.
  <br/><br/>
  As the name implies, the CNC shield was designed for 3+1 axis motion.
  This type of motion is commonly found in CNCs, 3D printers, and laser engravers. 
  There is specialized GRBL software for the Arduino CNC Shield V3.0 that translates G-code to drive the stepper motors to the correct positions.
  <br/><br/>
  However, the shield isn't only for 3D printers. You can use the shield for any arduino application where you 
  need several motors with good accuracy at low cost. At the time of writing (November 2022),
  you can purchase a CNC Shield V3.0, 4xA4988 motor drivers, and an Arduino Uno for only $26. 
  Add four budget 1.5 A steppers for $35, and you have the bulk of the project for only $60.
  This is a great budget way to get started with more complex Arduino motion projects.  
  <br/>
  
<h2>Parts List</h2>
<div className="twoColumnContainer">
  <div className="twoColumnItem">
    For this project, you'll need: 
    <ul>
      <li>An Arduino CNC Shield V3.0 </li>
      <li>1-4 stepper drivers: A4988 or DRV8825</li>
      <li>1-4 stepper motors that respect the <br/>current limits of the A4988 or DRV8825</li>
      <li>An Arduino Uno (or compatible)</li>
      <li>A suitable power supply</li>
    </ul>
  </div>
  <div className="twoColumnItem">
    Here's what I used:
    <ul className="blueLink">
      <li>An Arduino CNC Shield V3.0 </li>
    </ul>
  </div>
</div>


<h2>Step 1: Attach the drivers to the shield (correctly!)</h2>
Each driver can be installed by being inserted into one of the four sets of pre-installed femail pin headers.
Be careful to install the drivers in the correct orientation. The ENABLE (EN) pin of each driver should be inserted 
into the pin labeled EN on the CNC shield.
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle, minWidth:'400px', width:'60%'}}
    src={`${process.env.PUBLIC_URL}/img/cncShieldTutorial/enableShield.png`}
    alt={"Shield ENABLE pin locations"}
  />
  <div style={styles.imgCaption}>
  The driver ENABLE (EN) pins should be connected to the pins labeled EN on the CNC shield.
  </div>
</div>
<br/>
The images below illustrate where the enable pins can be found on both the A4988 and DRV8825 drivers.
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle, minWidth:'400px', width:'70%'}}
    src={`${process.env.PUBLIC_URL}/img/cncShieldTutorial/enableA4988.png`}
    alt={"A4988 ENABLE pin location"}
  />
  <div style={styles.imgCaption}>
  Location of the A4988 ENABLE pin.
  </div>
</div>
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle, minWidth:'400px', width:'70%'}}
    src={`${process.env.PUBLIC_URL}/img/cncShieldTutorial/enableDRV8825.png`}
    alt={"DRV8825 ENABLE pin location"}
  />
  <div style={styles.imgCaption}>
  Location of the DRV8825 ENABLE pin.
  </div>
</div>
<br/>
When installed correctly, the drivers should appear as illustrated below.
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle, minWidth:'400px', width:'100%'}}
    src={`${process.env.PUBLIC_URL}/img/cncShieldTutorial/enableInstalled.png`}
    alt={"Drivers installed"}
  />
  <div style={styles.imgCaption}>
  Left: DRV8825 drivers installed on the CNC shield. Right: A4988 drivers installed on the CNC shield.
  </div>
</div>

<h2>Step 2: Add the heat sinks</h2>
Typically the A4988 and DRV8825 drivers will come with heat sinks. You can attach these using the included double-sided tape.
For better heat transfer, you could replace the double-sided tape with thermal glue. 
For my not-that-hot system, I used the the included tape. 
<br/>
You have to be careful when attaching the heat sink. 
Place it in the center of the board so that it doesn't short out the pin headers.
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle, minWidth:'400px', width:'100%'}}
    src={`${process.env.PUBLIC_URL}/img/cncShieldTutorial/   .png`}
    alt={"Drivers installed"}
  />
  <div style={styles.imgCaption}>
  Place the heat sink is placed in the center of the breakout. Make sure it doesn't touch the pins!
  </div>
</div>

<h2>Step 3: Tune the current limits</h2>
Before connecting the steppers, you'll want to tune the current limits of the motor drivers. 
You can accomplish this by adjusting the trim pot with a small screwdriver.
<div style={styles.imgContainer}>
  <img
    style={{...styles.imgStyle, minWidth:'400px', width:'70%'}}
    src={`${process.env.PUBLIC_URL}/img/cncShieldTutorial/trimpots.png`}
    alt={"Drivers installed"}
  />
  <div style={styles.imgCaption}>
  Locations of the trim pots and ground pins.
  </div>
</div>
To tune the current limits, perform the following steps.
<ol>
  <li>Calculate the value of Vref (see below)</li>
  <li>Connect the Arduino to a power source to power the logic circuit of the driver.</li>
  <li>Measure the voltage between the potentiometer (pot) head and the ground pin on the driver.</li>
  <li>Turn the trim pot and measure again until you reach the calculated Vref.</li>
</ol>

<h3> Calculating Vref</h3>
The value of Vref depends on the current limit you want to set. 
<h4>DRV8825</h4>
For the DRV8825, you can calculate the reference voltage as
<center><code>V<sub>ref</sub> = I<sub>max</sub>/2 </code></center>
where <code>I<sub>max</sub></code> is the maximum current. For my motors, I want a maximum of 1.25 A, implying a reference voltage of
<center><code>V<sub>ref</sub> = (1.25)/2 = 0.75 V</code></center>

<h4>A4988</h4>
If you're using the A4988, the current limit can be set using
<center><code>V<sub>ref</sub> = 8R<sub>S</sub>I<sub>max</sub> </code></center>
In this formula, <code>I<sub>max</sub></code> is the maximum current setting
and <code>R<sub>S</sub></code> is the value of the sensor resistor present on your version of the A4988. 
My knock-off A4988 are not well-documented, so I can't read the value off the datasheet. According to once source I found,
the sensor resistor is labeled R5. On my board, R5 is labeld R100, 
so by that logic <code>R<sub>S</sub> = 0.1&#937;</code>. For my motors, I want a maximum of 1.25 A, implying a reference voltage of
<center><code>V<sub>ref</sub> = 8(0.1)(1.25) = 1.0 V</code></center>
Overall, this value makes reasonable sense as I can achieve it by adjusting the trim pot.
However, the maximum reference voltage I measured was 1.2 V implying a maximum current of 1.5 A, which is less than what I expect from the A4988.
Considering that these cost somewhere around $1-2 each, I'll accept that there's some room for error here.

<h2>Step 4: Connect the motors</h2>





<br/>
Thanks for reading!
</div> 
)
};
